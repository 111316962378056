<template>
    <div class="image-selector is-flex is-vcentered">
        <div v-for="option in filteredOptions" :key="option.Id" :class="option.selected === true ? 'is-selected' : 'not-selected'" @click="Select(option)">
            <figure class="image is-clickable mx-3 my-3">
                <image-component :name="option.Src" :alt="option.Name" :size="widthSize" @load="Loaded"></image-component>
                <fast-tooltip v-if="!isMobile && (type==='style'||type==='application')" :src="option.Src" width="400px" class="ftt_overlay">
                    <o-icon icon="search-plus" class="mx-2 my-2"></o-icon>
                </fast-tooltip>
            </figure>
            <div class="has-text-centered">
                {{option.Name}}
                <template v-if="option.Name.includes('®')">
                    <br />
                    <i class="patent-text">US Patent No’s #9,765,988 #10,871,305</i>
                </template>
            </div>
        </div>
        <div v-if="edit">
            <o-icon icon="plus-square" pack="far" size="large" clickable @click="$emit('add')"></o-icon>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import { image } from "@/components";
    import fastTooltip from "@/components/fastTooltip.vue";

    export default {
        components: {
            "image-component": image,
            "fast-tooltip": fastTooltip
        },
        props: {
            modelValue: {
                type: [Array, Object],
                default: () => {
                    return {};
                }
            },
            multi: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: ''
            },
            excludeJBead: {
                type: Boolean,
                default: false
            },
            data: {
                type: Array,
                default: () => { return null; }
            },
            edit: {
                type: Boolean,
                default: false
            },
            size: {
                type: [String, Number],
                default: 13
            }
        },
        emits: ["update:modelValue", "input", "init", "changed", "load"],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $moment = inject("$moment");
            const $success = inject("$success");
            const $error = inject("$error");
            const $mobileCheck = inject("$mobileCheck");

            const isMobile = $mobileCheck();
            let options;

            const filteredOptions = computed(() => {
                return options.value;
            });

            const CheckModelToSelect = () => {
                if (props.multi) {
                    for (var k in props.modelValue) {
                        const item = props.modelValue[k];
                        for (var j in options.value) {
                            if (options.value[j].Id === item.Id) {
                                options.value[j].selected = true;
                            }
                        }
                    }
                }
                else {
                    for (var i in options.value) {
                        if (props.modelValue.Id && options.value[i].Id === props.modelValue.Id)
                            options.value[i].selected = true;
                        else
                            options.value[i].selected = false;
                    }
                }
            }

            const GetOptions = async (src) => {
                try {
                    var response = await $http.get(src);
                    options.value = response.data;
                    CheckModelToSelect();
                }
                catch (err) {
                    console.error(err);
                }
            }

            if (props.data !== null) {
                options = ref(props.data);
            }
            else {
                options = ref([]);
                const type = props.type.toUpperCase();
                if (type === "GRILLETYPE" || type === "GRILLTYPE") {
                    GetOptions("/api/grilleType");
                    if (props.excludeJBead) {
                        options.value = options.value.filter(x => x.Id != 5);
                    }
                }
                else if (type === "GRILLEFRAMETYPE" || type === "GRILLFRAMETYPE") {
                    GetOptions("/api/grilleFrameType");
                }
                else if (type === "GRILLESTYLETYPE" || type === "GRILLSTYLETYPE") {
                    GetOptions("/api/grilleStyleType");
                }
                else if (type === "GRILLEMATERIALTYPE" || type === "GRILLMATERIALTYPE") {
                    GetOptions("/api/grilleMaterialType");
                }
                else if (type === "GRILLEFINISHTYPE" || type === "GRILLFINISHTYPE") {
                    GetOptions("/api/grilleFinishType");
                }
                else if (type === "GRILLETHICKNESSTYPE" || type === "GRILLTHICKNESSTYPE") {
                    GetOptions("/api/grilleThicknessType");
                }
            }

            const CreateModel = () => {
                var model = options.value.filter(x => x.selected === true);
                if (!props.multi)
                    model = model[0] || {};

                context.emit("update:modelValue", model);
                context.emit("changed", model);
            };

            const Loaded = () => {
                context.emit("load");
            }


            watch(() => props.data, (n, o) => {
                options.value = n;
                CheckModelToSelect();
            });
            watch(() => props.modelValue, (n, o) => {
                if (n != o) {
                    CheckModelToSelect();
                }
            });
            CheckModelToSelect();

            const widthSize = computed(() => {
                if (!isNaN(props.size))
                    return props.size + "em";
                else
                    return props.size;
            });

            const Select = (option) => {
                if (props.multi) {
                    option.selected = !option.selected;
                }
                else {
                    for (var i = 0; i < options.value.length; i++) {
                        options.value[i].selected = false;
                    }
                    option.selected = true;
                }
                context.emit("input", option);
                CreateModel();
                CheckModelToSelect();
            };

            context.emit("init", {
                Reset: () => {
                    for (var i = 0; i < options.value.length; i++) {
                        options.value[i].selected = false;
                    }
                    CreateModel();
                    CheckModelToSelect();
                }
            });

            return {
                isMobile,
                options,
                filteredOptions,
                widthSize,
                CreateModel,
                Loaded,
                Select
            };
        }
    }

</script>
<style lang="scss" scoped>
    .appSelect {
        display: flex;
        align-items: baseline;

        & label {
            display: inline-block;
            margin-right: 1em;
        }
    }

    div > .has-text-centered {
        line-height: 0.85em;
    }

    .patent-text {
        max-width: 10em;
        font-weight: 700;
        font-size: 0.7em;
    }

    .image-selector .fastTooltipControl img {
        max-width: none;
    }
</style>
