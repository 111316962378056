import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Axios from 'axios';
import Oruga from '@oruga-ui/oruga-next';
import '@oruga-ui/oruga-next/dist/oruga.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@fortawesome/fontawesome-pro/css/all.css';
import '@fortawesome/fontawesome-pro/js/all.js';
import momentjs from 'moment';
import braintree from 'braintree-web';
import { loading, image } from "@/components";
import Services from "@/services";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Axios.defaults.baseURL = process.env.VUE_APP_API;
Axios.interceptors.request.use(config => { config.headers["Authorization"] = "Bearer " + localStorage.getItem("AUTH_TOKEN"); return config; });

import { bulmaConfig } from "@oruga-ui/theme-bulma";
import '@/assets/scss/plugin.scss';

import '@/assets/css/global.scss';

const app = createApp(App);
app.config.globalProperties.$moment = momentjs;

app
    .component("loading", loading)
    .component("fa-icon", FontAwesomeIcon)
    .component("image-component", image);
app
    .provide("$http", Axios)
    .provide("$moment", momentjs)
    .provide("$braintree", braintree);
app
    .use(Oruga, {
        iconPack: 'far',
        iconComponent: 'fa-icon',
        ...bulmaConfig
    })
    .use(VueTelInput, {
        mode: 'auto',
        autoFormat: true,
        inputOptions: { placeholder: "Enter a phone number" },
        validCharactersOnly: true
    })
    .use(Services.notification.Success)
    .use(Services.notification.Error)
    .use(Services.notification.Warn)
	.use(Services.httpGetCached)
	.use(Services.mobileCheck)
    .use(store)
    .use(router);
router.app = app;
app.config.globalProperties.$refreshToken = Services.refreshToken;
app.mount('#app');

//How to customize Oruga: https://github.com/oruga-ui/demo-multiframework/blob/master/src/views/Bulma.vue
