<template>
    <div class="preview-image mx-1 my-1" v-for="(item, index) in rows" :key="index">
        <image-component :name="item.Src" :alt="item.Name" :size="size" @load="item.loaded = true"></image-component>
        <div class="has-text-centered" style="cursor:default">
            {{item.Name}}
        </div>
        <button class="delete is-large" @click="Remove(item, index)" v-if="item.loaded">
        </button>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject, nextTick } from "vue";
    import { image } from "@/components";
    export default {
        components: {
            "image-component": image
        },
        props: {
            data: {
                type: Array,
                default: () => { return [] }
            },
            size: {
                type: [String, Number],
                default: 8
            },
            removable: {
                type: Boolean,
                default: false
            }
        },
        emits: ["remove"],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const rows = computed(() => {
                return props.data;
            });

            const Remove = (row, index) => {
                context.emit("remove", { row: row, index: index });
            };

            onMounted(() => {
                //
            });

            return {
                rows,
                Remove
            };
        }
    }

</script>
<style scoped>
    .preview-image {
        position: relative;
    }

        .preview-image > button.delete {
            position: absolute;
            top: 0;
            right: 0;
            background-color: #cd5c5cbd;
        }

            .preview-image > button.delete:hover {
                background-color: #cd5c5cdb;
            }
</style>