import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store';
import { AppRouter } from '@/types';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'MainTemplate',
        component: () => import(/* webpackChunkName: "mainTemplate" */ '@/pages/mainTemplate.vue'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import(/* webpackChunkName: "home" */ '@/pages/home.vue'),
                meta: {
                    title: 'Home'
                }
            },
            {
                path: '/login',
                name: 'login',
                component: () => import(/* webpackChunkName: "login" */ '@/pages/login.vue'),
                meta: {
                    title: 'Login'
                }
            },
            {
                path: '/logout',
                name: 'logout',
                component: () => import(/* webpackChunkName: "logout" */ '@/pages/logout.vue'),
                meta: {
                    title: 'Logout'
                }
            },
            {
                path: '/forgotPassword',
                name: 'Forgot',
                component: () => import(/* webpackChunkName: "forgot" */ '@/pages/forgotpassword.vue'),
                meta: {
                    title: 'Forgot Password'
                }
            },
            {
                path: '/resetPassword/:code',
                name: 'ResetPassword',
                component: () => import(/* webpackChunkName: "resetPassword" */ '@/pages/resetpassword.vue'),
                props: true,
                meta: {
                    title: 'Reset Password'
                }
            },
            {
                path: '/rfq',
                name: 'rfq',
                component: () => import(/* webpackChunkName: "rfq" */ '@/pages/rfq.vue'),
                meta: {
                    title: 'Request a Quote'
                }
            },
            {
                path: '/thankyou',
                name: 'thankyou',
                component: () => import(/* webpackChunkName: "rfq" */ '@/pages/thankyou.vue'),
                meta: {
                    title: 'Request a Quote'
                }
            },
            //{
            //    path: '/scheduleBuilder',
            //    name: 'scheduleBuilder',
            //    component: () => import(/* webpackChunkName: "rfq" */ '@/pages/scheduleBuilder.vue'),
            //    meta: {
            //        title: 'Request a Quote'
            //    }
            //},
            //{
            //    path: '/rapidspec',
            //    name: 'rapidspec',
            //    component: () => import(/* webpackChunkName: "rapidspec" */ '@/pages/rapidspec.vue')
            //},
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import(/* webpackChunkName: "rfqReport" */ '@/pages/dashboard.vue'),
                meta: {
                    title: 'Dashboard'
                }
            },
            {
                path: '/register',
                name: 'register',
                component: () => import(/* webpackChunkName: "register" */ '@/pages/register.vue'),
                meta: {
                    title: 'Register'
                }
            },
            {
                path: '/profile',
                name: 'profile',
                component: () => import(/* webpackChunkName: "profile" */ '@/pages/profile.vue'),
                meta: {
                    title: 'Profile'
                }
            },
            {
                path: '/grilles',
                name: 'grilles',
                component: () => import(/* webpackChunkName: "grilles" */ '@/pages/grilles.vue'),
                meta: {
                    title: 'Saved Grilles'
                }
            },
            {
                path: '/schedules',
                name: 'schedules',
                component: () => import(/* webpackChunkName: "schedules" */ '@/pages/schedules.vue'),
                meta: {
                    title: 'Saved Schedules'
                }
            },
            {
                path: '/documents',
                name: 'documents',
                component: () => import(/* webpackChunkName: "schedules" */ '@/pages/documents.vue'),
                meta: {
                    title: 'Documents'
                }
            },
            {
                path: '/schedules/current',
                name: 'currentSchedule',
                component: () => import(/* webpackChunkName: "schedules" */ '@/pages/schedules/current.vue'),
                meta: {
                    title: 'Current Schedule'
                }
            },
            {
                path: '/freetools/weight-calculator',
                name: 'weight-calculator',
                component: () => import(/* webpackChunkName: "freetools" */ '@/pages/freetools/weight-calculator.vue'),
                meta: {
                    title: 'Weight Calculator'
                }
            },
            {
                path: '/freetools/airflow-calculator',
                name: 'airflow-calculator',
                component: () => import(/* webpackChunkName: "freetools" */ '@/pages/freetools/airflow-calculator.vue'),
                meta: {
                    title: 'Airflow Calculator'
                }
            },
            {
                path: '/freetools/open-area-linear-calculator',
                name: 'open-area-linear-calculator',
                component: () => import(/* webpackChunkName: "freetools" */ '@/pages/freetools/open-area-linear-calculator.vue'),
                meta: {
                    title: 'Open Area Calculator: Linear'
                }
            },
            {
                path: '/freetools/open-area-perforated-calculator',
                name: 'open-area-perforated-calculator',
                component: () => import(/* webpackChunkName: "freetools" */ '@/pages/freetools/open-area-perforated-calculator.vue'),
                meta: {
                    title: 'Open Area Calculator: Perforated'
                }
            }
        ]
    },
    {
        path: '/admin',
        name: 'admin',
        redirect: '/admin/dashboard',
        component: () => import(/* webpackChunkName: "adminTemplate" */ '@/pages/admin/adminTemplate.vue'),
        meta: {
            title: 'Admin'
        },
        children: [
            {
                path: '/admin/report/rfq',
                name: 'quoteReport',
                component: () => import(/* webpackChunkName: "rfqReport" */ '@/pages/admin/report/rfq.vue'),
                meta: {
                    title: 'RFQ Report'
                }
            },
            {
                path: '/admin/users',
                name: 'searchUsers',
                component: () => import(/* webpackChunkName: "search" */ '@/pages/admin/usermanagement/search.vue'),
                meta: {
                    title: 'Users Search'
                }
            },
            {
                path: '/admin/users/:uid',
                name: 'manageUser',
                component: () => import(/* webpackChunkName: "manage" */ '@/pages/admin/usermanagement/manage.vue'),
                props: true,
                meta: {
                    title: 'Manage User'
                }
            },
            {
                path: '/admin/dashboard',
                name: 'adminDashboard',
                component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/dashboard.vue'),
                meta: {
                    title: 'Admin Dashboard'
                }
            },
            {
                path: '/admin/grille/applicationTypes',
                name: 'adminApplicationTypes',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/applicationTypes.vue'),
                meta: {
                    title: 'Grille Management: Applications'
                }
            },
            {
                path: '/admin/grille/applicationTypes/:Id',
                name: 'adminApplicationType',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/applicationType.vue'),
                meta: {
                    title: 'Grille Management: Applications'
                }
            },
            {
                path: '/admin/grille/grilleTypes',
                name: 'adminGrilleTypes',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grilleTypes.vue'),
                meta: {
                    title: 'Grille Management: Types'
                }
            },
            {
                path: '/admin/grille/grilleTypes/:Id',
                name: 'adminGrilleType',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grilleType.vue'),
                meta: {
                    title: 'Grille Management: Types'
                }
            },
            {
                path: '/admin/grille/grilleStyleTypes',
                name: 'adminGrilleStyleTypes',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grilleStyleTypes.vue'),
                meta: {
                    title: 'Grille Management: Styles'
                }
            },
            {
                path: '/admin/grille/grilleStyleTypes/:Id',
                name: 'adminGrilleStyleType',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grilleStyleType.vue'),
                meta: {
                    title: 'Grille Management: Styles'
                }
            },
            {
                path: '/admin/grille/grillePatternTypes',
                name: 'adminGrillePatternTypes',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grillePatternTypes.vue'),
                meta: {
                    title: 'Grille Management: Patterns'
                }
            },
            {
                path: '/admin/grille/grillePatternTypes/:Id',
                name: 'adminGrillePatternType',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grillePatternType.vue'),
                meta: {
                    title: 'Grille Management: Patterns'
                }
            },
            {
                path: '/admin/grille/grilleFrameTypes',
                name: 'adminGrilleFrameTypes',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grilleFrameTypes.vue'),
                meta: {
                    title: 'Grille Management: Frames'
                }
            },
            {
                path: '/admin/grille/grilleFrameTypes/:Id',
                name: 'adminGrilleFrameType',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grilleFrameType.vue'),
                meta: {
                    title: 'Grille Management: Frames'
                }
            },
            {
                path: '/admin/grille/grilleFinishTypes',
                name: 'adminGrilleFinishTypes',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grilleFinishTypes.vue'),
                meta: {
                    title: 'Grille Management: Finishes'
                }
            },
            {
                path: '/admin/grille/grilleFinishTypes/:Id',
                name: 'adminGrilleFinishType',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grilleFinishType.vue'),
                meta: {
                    title: 'Grille Management: Finishes'
                }
            },
            {
                path: '/admin/grille/grilleMaterialTypes',
                name: 'adminGrilleMaterialTypes',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grilleMaterialTypes.vue'),
                meta: {
                    title: 'Grille Management: Materials'
                }
            },
            {
                path: '/admin/grille/grilleMaterialTypes/:Id',
                name: 'adminGrilleMaterialType',
                component: () => import(/* webpackChunkName: "adminGrille" */ '@/pages/admin/grille/grilleMaterialType.vue'),
                meta: {
                    title: 'Grille Management: Materials'
                }
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
}) as AppRouter;

router.beforeEach((to, from, next) => {
    const self = router.app.config.globalProperties;

    const suffix = ' - AAG';
    if (to.meta && to.meta.title) {
        document.title = to.meta.title + suffix;
    } else {
        document.title = 'Advanced Architectural Grilleworks';
    }

    if (store.getters.isLoggedIn) {
        const expires = new Date(store.getters.user.ExpiresOn);
        if (Date.now() >= expires.getTime()) {
            //refresh user. If can't, redirect to login
            self.$refreshToken(true);
        }
    }

    if (to.path.startsWith("/admin") && !store.getters.isAdmin) {
        router.push({ name: "login" });
    } else {
        next();
    }
});

export default router
