<template>
	<div ref="controlElement" class="fastTooltipControl" @click="Close" @pointerenter="Open" @pointerleave="Close">
        <slot></slot>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	export default {
		props: {
			src: {
				type: String,
				default: ""
            },
            innerHTML: {
                type: String,
                default: ""
            },
            listSrc: {
                type: String,
                default: ""
            },
            position: {
                type: String,
                default: "left"
            },
            width: {
                type: String,
                default: ""
            },
            maxWidth: {
                type: String,
                default: ""
            },
			disabled: {
				type: Boolean,
				default: false
			}
		},
		emits: ["disabled"],
        setup(props, context) {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $httpGetCached = inject("$httpGetCached");
			const $moment = inject("$moment");
            const $success = inject("$success");
            const $error = inject("$error");

			const api = ref(process.env.VUE_APP_API);
			const options = ref([]);
            const srcGetter = computed(() => {
                return props.src;
            });

            const controlElement = ref(null);

            const bodyHtml = ref("");

            if (!global.$fastTooltipRoot) {
                global.$fastTooltipRoot = ref(document.createElement("div"));
                global.$fastTooltipRoot.value.style.cssText = "display: block; opacity: 0;";
                global.$fastTooltipRoot.value.id = "fastTooltipRoot";
                document.body.appendChild(global.$fastTooltipRoot.value);
                global.$fastTooltipImg = ref(document.createElement("img"));
                global.$fastTooltipRoot.value.appendChild(global.$fastTooltipImg.value);
                global.$fastTooltipBody = ref(document.createElement("div"));
                global.$fastTooltipBody.value.classList.add("ftt_content");
                global.$fastTooltipRoot.value.appendChild(global.$fastTooltipBody.value);
            }

            const GetElementMeasurements = (el) => {
                const clientRect = el.getBoundingClientRect();
                return {
                    top: clientRect.top + window.scrollY,
                    left: clientRect.left + window.scrollX,
                    width: el.offsetWidth,
                    height: el.offsetHeight
                };
            };

            const Open = () => {
                const fttRoot = global.$fastTooltipRoot.value;
                switch (props.position) {
                    case "top":
                    case "top-right":
                    case "right":
                    case "bottom":
                    case "left":
                        fttRoot.className = "ftt_is-" + props.position;
                        break;
                }

                const fttImg = global.$fastTooltipImg.value;
                const fttBody = global.$fastTooltipBody.value;
                if (bodyHtml.value) {
                    fttRoot.style.borderRadius = "6px";
                    fttImg.style.borderRadius = "4px 4px 0 0";
                    fttBody.innerHTML = bodyHtml.value;
                    fttBody.style.display = "block";
                } else {
                    fttRoot.style.borderRadius = "0";
                    fttImg.style.borderRadius = "0";
                    fttBody.innerHTML = "";
                    fttBody.style.display = "none";
                }

                if (props.width) {
                    fttImg.style.width = props.width;
                }

                if (props.maxWidth) {
                    fttImg.style.maxWidth = props.maxWidth;
                } else {
                    fttImg.style.maxWidth = "none";
                }

                const m1 = GetElementMeasurements(controlElement.value);
                const PositionRoot = () => {
                    fttRoot.style.visibility = "visible";
                    fttRoot.style.opacity = 1;
                    const m2 = GetElementMeasurements(fttRoot);
                    const offset = 12;
                    const borderWidth = 2;
                    switch (props.position) {
                        case "top":
                        case "bottom":
                            fttRoot.style.left = `${m1.left - (m2.width - m1.width) / 2 - borderWidth}px`;
                            if (props.position === "top") {
                                fttRoot.style.top = `${m1.top - m2.height - offset}px`;
                            } else {
                                fttRoot.style.top = `${m1.top + m1.height + offset}px`;
                            }
                            break;
                        case "left":
                        case "right":
                            fttRoot.style.top = `${m1.top - (m2.height - m1.height) / 2 - borderWidth}px`;
                            if (props.position === "left") {
                                const left = m1.left - m2.width - offset;
                                if (left >= 0) {
                                    fttRoot.style.left = `${left}px`;
                                } else {
                                    fttRoot.style.left = `${m1.left + m1.width + offset}px`;
                                    fttRoot.classList.add("ftt_is-switched");
                                }
                            } else {
                                fttRoot.style.left = `${m1.left + m1.width + offset}px`;
                            }
                            break;
                    }
                };

                if (srcGetter.value) {
                    fttImg.onload = PositionRoot;
                    fttImg.src = `${api.value + 'api/image/' + srcGetter.value}`;
                } else {
                    fttImg.src = "";
                    PositionRoot()
                }
            }

            const Close = () => {
                const fttRoot = global.$fastTooltipRoot.value;
                fttRoot.style.opacity = 0;
                fttRoot.style.visibility = "none";
            }

			const isDisabled = computed(() => {
				return props.disabled || srcGetter.value === "" || options.value.length === 0;
			});
			const isMobile = computed(() => {
				return props.mobile;
            });

            const GetListHtml = async (src) => {
                let options;
                try {
                    const response = await $httpGetCached(src);
                    options = response.data;
                }
                catch (err) {
                    $error("Failed to get frame applications.");
                    return "";
                }
                if (options.length === 0) {
                    return " ";
                }
                let htmlString = "<h2>Suitable applications:</h2><ul>";
                for (const application of options) {
                    htmlString += `<li>${application.Name}</li>`;
                }
                htmlString += "</ul>"
                return htmlString;
            };

            onMounted(async () => {
                bodyHtml.value = props.innerHTML;
                if (props.listSrc) {
                    bodyHtml.value += await GetListHtml(props.listSrc);
                }                
            });

			return {
				api,
                options,
                controlElement,
                isDisabled,
                Open,
                Close
			};
		}
	}

</script>

<style lang="scss">
	@import "@/assets/css/globalVars.scss";

    #fastTooltipRoot {
        $arrow-width: 6px;
        $extra: 2px;
        $border-width: 2px;
        position: absolute;
        z-index: 400;
        margin: 0;
        box-sizing: content-box;
        min-width: 20px;
        min-height: 20px;
        padding: 0;
        pointer-events: none;
        border: $border-width solid $info;
        background-color: $info;
        line-height: 0;
        transition: opacity linear 0.075s;
        font-size: 1rem;

        & img {
            max-width: 12rem;
        }

        & .ftt_content {
            padding: 0.35rem 0.75rem;
            line-height: 1;
            font-size: 1rem;
            font-weight: 400;
            color: white;

            & h2 {
                font-size: 1em;
            }

            & ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-evenly;
                gap: 1em;
                row-gap: 0.25em;
                margin-top: 0.25em;
                max-width: 10em;


                & li {
                    display: inline-block;

                    &::before {
                        content: "\2022";
                    }
                }
            }

            & li {
                display: list-item;
            }
        }

        &::after {
            content: "";
            z-index: -1;
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border: #{$arrow-width + $extra} solid transparent;
            line-height: 0;
        }

        &.ftt_is-top::after {
            bottom: -($arrow-width * 2 + $border-width);
            left: calc(50% - $arrow-width);
            border-top-color: $info;
            border-bottom-color: transparent;
        }

        &.ftt_is-top-right::after {
            bottom: -($arrow-width * 2 + $border-width);
            left: calc(20% - $arrow-width);
            border-top-color: $info;
            border-bottom-color: transparent;
        }

        &.ftt_is-right::after, &.ftt_is-left.ftt_is-switched::after {
            top: calc(50% - $arrow-width);
            left: -($arrow-width * 2 + $border-width);
            border-right-color: $info;
            border-left-color: transparent;
        }

        &.ftt_is-bottom::after {
            top: -($arrow-width * 2 + $border-width);
            left: calc(50% - $arrow-width);
            border-bottom-color: $info;
            border-top-color: transparent;
        }

        &.ftt_is-left {
            &::after {
                top: calc(50% - $arrow-width);
                right: -($arrow-width * 2 + $border-width);
                border-left-color: $info;
            }
        }
    }

    .fastTooltipControl {
        height: fit-content;
        line-height: 0;

        & .icon {
            position: relative;
            z-index: 2;
            display: block;
            margin: 0 !important;
            width: unset;
            min-width: 10px;
            height: unset;
            padding: 0;
            color: $info;
        }
    }
</style>
