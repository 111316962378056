<template>
    <div :class="rootClass">
        <div v-for="option in options" :key="option.Id" :class="[option.selected === true ? 'is-selected' : '']" @click="Select(option)">
            <figure class="image has-text-centered is-clickable mx-3 my-3">
                <img v-if="option.Src" :src="`${api + 'api/image/' + option.Src || 0}`" />
                <span :style="{ 'font-size': widthSize, 'margin': margin }">{{option.Name}}</span>
                <div class="has-text-centered">
                    <span class="is-size-7">{{option.Description}}</span>
                </div>
            </figure>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {
            modelValue: {
                type: [Array, Object],
                default: () => {
                    return {};
                }
            },
            multi: {
                type: Boolean,
                default: false
            },
            data: {
                type: Array,
                default: () => { return null; }
            },
            size: {
                type: [String, Number],
                default: 2
            },
            margin: {
                type: String,
                default: "1px"
            },
            rootClass: {
                type: String,
                default: "image-selector is-flex columns is-vcentered"
            }
        },
        emits: ["update:modelValue", "input", "init", "changed"],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $httpGetCached = inject("$httpGetCached");
            const $moment = inject("$moment");
            const $success = inject("$success");
            const $error = inject("$error");

            const api = ref(process.env.VUE_APP_API);
            var options;

            const CheckModelToSelect = (model) => {
                if (props.multi) {
                    for (var k in model) {
                        const item = model[k];
                        for (var j in options.value) {
                            if (options.value[j].Id !== undefined && options.value[j].Id !== null) {
                                if (options.value[j].Id === item.Id) {
                                    options.value[j].selected = true;
                                }
                            }
                            else {
                                if (options.value[j].Name === item.Name) {
                                    options.value[j].selected = true;
                                }
                            }
                        }
                    }
                }
                else {
                    for (var i in options.value) {
                        if (options.value[i].Id !== undefined && options.value[i].Id !== null) {
                            if (options.value[i].Id === model.Id)
                                options.value[i].selected = true;
                            else
                                options.value[i].selected = false;
                        }
                        else {
                            if (options.value[i].Name === model.Name)
                                options.value[i].selected = true;
                            else
                                options.value[i].selected = false;
                        }
                    }
                }
            }

            if (props.data !== null) {
                options = ref(props.data);
            }

            const CreateModel = () => {
                var model = options.value.filter(x => x.selected === true);
                if (!props.multi)
                    model = model[0] || {};

                context.emit("update:modelValue", model);
                context.emit("changed", model);
            };

            watch(() => props.data, (n, o) => {
                options.value = n;
                CheckModelToSelect(props.modelValue);
            });
            watch(() => props.modelValue, (n, o) => {
                if (!n) {
                    options.value = [];
                }

                if (n != o) {
                    CheckModelToSelect(n);
                }
            });
            CheckModelToSelect(props.modelValue);

            const widthSize = computed(() => {
                if (!isNaN(props.size))
                    return props.size + "em";
                else
                    return props.size;
            });

            const Select = (option) => {
                if (props.multi) {
                    option.selected = !option.selected;
                }
                else {
                    for (var i = 0; i < options.value.length; i++) {
                        options.value[i].selected = false;
                    }
                    option.selected = true;
                }
                context.emit("input", option);
                CreateModel();
            };

            return {
                api,
                options,
                widthSize,
                CreateModel,
                Select
            };
        }
    }

</script>
<style>
    .text-selector {
        flex-flow: row wrap;
        justify-content: space-around;
    }

        .text-selector > div.is-selected {
            border: 2px solid black;
            border-radius: 5px;
        }
</style>
