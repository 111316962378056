<template>
    <div v-if="loading">
        <span class="has-text-centered">
            <loading icon="spinner-third" spin :pulse="false"></loading>
        </span>
    </div>
    <span v-else-if="isSummary">
        {{ grilles.length }}
    </span>
    <div v-else>
        <span class="error" v-if="error">Problem loading grilles. Please refresh the page.</span>

        <span v-if="grilles.length == 0 && !error">
            <slot name="empty">
                <div class="my-4">Save frequently requested grilles</div>
                <router-link class="button" to="/rfq">Configure a new grille</router-link>
            </slot>
        </span>

        <o-table :data="grilles" v-else>
            <o-table-column label="Type" field="Type.Name" sortable>
                <template v-slot="props">
                    {{ props.row.Type.Name }}
                </template>
            </o-table-column>
            <o-table-column label="Frame" field="GrilleFrame.Frame.Name" sortable>
                <template v-slot="props">
                    {{ props.row.GrilleFrame?.Frame?.Name || "Unavailable" }}
                </template>
            </o-table-column>
            <o-table-column label="Style" field="GrilleStyle.Style.Name" sortable>
                <template v-slot="props">
                    {{ props.row.GrilleStyle.Style.Name }}
                </template>
            </o-table-column>
            <o-table-column label="Material"
                            field="GrilleMaterial.Material.Name"
                            sortable>
                <template v-slot="props">
                    {{ props.row.GrilleMaterial.Material.Name }}
                </template>
            </o-table-column>
        </o-table>
    </div>
</template>
<script>
    import {
        getCurrentInstance,
        ref,
        computed,
        watch,
        onMounted,
        inject,
    } from "vue";
    export default {
        props: {
            userId: {
                type: String,
                default: undefined,
            },
            isSummary: {
                type: Boolean,
                default: undefined,
            },
        },
        emits: [],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const loading = ref(true);
            const error = ref(false);
            const grilles = ref([]);

            const GetGrilles = async () => {
                try {
                    const userId = props.userId;
                    if (userId) {
                        const response = await $http.get(
                            "/api/grille/user/" + userId + "/list"
                        );
                        grilles.value = response.data;
                        return response;
                    } else {
                        const response = await $http.get("/api/grille/list");
                        grilles.value = response.data;
                        return response;
                    }
                } catch (err) {
                    error.value = true;
                }
            };

            onMounted(async () => {
                await GetGrilles();
                loading.value = false;
            });

            return {
                loading,
                error,
                grilles,
            };
        },
    };
</script>