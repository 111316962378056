import {
    App,
    getCurrentInstance,
    inject,
} from "vue";
import Axios from 'axios';
import store from '@/store';

const httpGetCached = {
    install(app: App, options = {}) {
        const global = getCurrentInstance()?.appContext.config.globalProperties;
        app.provide("$httpGetCached", async (url) => {
            const cache = await store.getters.HttpGet(url);
            if (cache) {
                return JSON.parse(JSON.stringify(cache));
            } else {
                const response = await Axios.get(url);
                store.dispatch("setGetResult", { key: url, value: response })
                return response;
                
            }
        });
    }
}

export default httpGetCached;
