<template>
	<div class="modal-card">
		<header class="modal-card-head">
			<p class="modal-card-title">Quote details</p>
			<o-icon type="button" icon="times" @click="$emit('close')" clickable></o-icon>
		</header>
		<section class="modal-card-body">
			<div class="columns">
				<div class="column is-12">
					<o-table :mobile-cards="false" :data="grilles">
						<o-table-column field="GrilleStyle.Name" label="Grille" sortable v-slot="props">
							<img
								:src="api + 'api/image/' + props.row?.GrilleStyle?.Style.Src"
								:alt="props.row?.GrilleStyle?.Style.Name"
								style="max-width: 4em; margin: auto" />
						</o-table-column>
						<o-table-column field="Type.Name" label="Description" sortable>
							<template v-slot="props">
								<o-collapse class="expando-table" animation="slide" :open="true">
									<template v-slot:trigger="p" class="card-header" role="button">
										<p
											class="expando-table-header-title is-flex"
											style="justify-content: space-between">
											<span>
												{{ props.row.GrilleStyle.Style.Name }}
												<span v-show="props.row?.GrilleFrame?.Frame.Id">
													- {{ props.row?.GrilleFrame?.Frame.Name }}
												</span>
											</span>
											<span>
												<a
													class="expando-table-header-icon"
													style="display: flex; flex-direction: column; align-items: center">
													<span style="font-size: 10px">
														{{ p.open ? "hide" : "show" }} details
													</span>
													<o-icon :icon="p.open ? 'caret-up' : 'caret-down'"></o-icon>
												</a>
											</span>
										</p>
									</template>
									<div class="expando-table-content">
										<div class="content">
											<o-field label="Type">
												{{ props.row.Type.Name }}
											</o-field>
											<o-field label="Sizes" grouped>
												<table class="simple-table">
													<tr>
														<th>Measurement</th>
														<th>Dimensions</th>
														<th>Amount</th>
													</tr>
													<tr v-for="size in props.row?.GrilleSizes" :key="size">
														<td>{{ size.MeasurementTypeId == 1 ? "Opening:" : "Overall:" }}</td>
														<td>
															{{ size.Height }}&quot;H&nbsp;&#215;&nbsp;{{ size.Length }}&quot;L
														</td>
														<td>{{ size.Quantity }}</td>
													</tr>
												</table>
											</o-field>
											<o-field v-if="props.row?.GrilleThickness" label="Thickness">
												{{ props.row.GrilleThickness.Thickness.Name }}
											</o-field>
											<o-field label="Frame" v-if="props.row?.GrilleFrame">
												{{ props.row?.GrilleFrame?.Frame.Name }}
											</o-field>
											<o-field label="Material">
												{{ props.row.GrilleMaterial.Material.Name }}
											</o-field>
											<o-field label="Finish">
												{{ props.row.GrilleFinish.Finish.Name }}
											</o-field>
											<o-field label="Other" v-if="RowHasOptions(props.row)">
												<span v-if="props.row.GrilleOption?.Application">
													{{ props.row.GrilleOption?.Application }}
													<br />
												</span>
												<span v-if="props.row.GrilleOption?.HasMountingHoles">
													w/ Mounting Holes
													<br />
												</span>
												<span v-if="props.row.GrilleOption?.IsFloorApplication">
													Floor
													<br />
												</span>
												<span v-if="props.row.GrilleOption?.HasRemovableCore">Removable Core</span>
											</o-field>
										</div>
									</div>
								</o-collapse>
							</template>
						</o-table-column>
						<o-table-column field="Quantity" label="Quantity" sortable numeric v-slot="props">
							{{ TotalAmount(props.row) }}
						</o-table-column>
					</o-table>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	export default {
		props: {
			grilles: {
				type: Array,
				default: () => {
					return [];
				}
			}
		},
		emit: ["close"],
		setup(props, context) {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const api = ref(process.env.VUE_APP_API);
			const contact = ref({});
			const sending = ref(false);
			const saving = ref(false);

			const sizes = computed(() => {
				return props.row?.GrilleSizes;
			});

			onMounted(() => {
				contact.value = global.$store.getters.contact;
			});

			const Close = () => {
				context.emit("close");
			};

			const lastSize = computed(() => {
				const last = props.row?.GrilleSizes[props.row?.GrilleSizes.length - 1];
				return last;
			});

			const TotalAmount = (row) => {
				let total = 0;
				for (const size of row.GrilleSizes) {
					if (size.Quantity) {
						total += parseInt(size.Quantity);
					}
				}
				return total;
			};

			const RowHasOptions = (row) => {
				return (
					row.GrilleOption &&
					(row.GrilleOption.Application ||
						row.GrilleOption.HasMountingHoles ||
						row.GrilleOption.HasRemovableCore)
				);
			};

			return {
				api,
				contact,
				sending,
				saving,
				Close,
				sizes,
				lastSize,
				TotalAmount,
				RowHasOptions
			};
		}
	};
</script>
<style lang="scss">
	.simple-table {
		border: 1px solid #e1e1e1;
		border-collapse: separate;
		border-radius: 0.5em;

		& th {
			font-size: 0.75em;
			border-bottom-width: 1px !important;
			background-color: #f5f5f5;

			&:first-child {
				border-radius: 0.6em 0 0 0;
			}

			&:last-child {
				border-radius: 0 0.6em 0 0;
			}
		}

		& td {
			border-bottom-width: 1px !important;

			&:first-child {
				border-left-width: 0 !important;
			}
		}

		& tr:last-child td {
			border-bottom-color: inherit;
			border-bottom-width: 0 !important;
		}

		& td:last-child,
		& th:last-child {
			border-left-width: 1px !important;
		}

		& input {
			height: 1.5em;
			width: 2.5em;
			padding: 0 0 0 0.3em;
		}
	}
</style>
