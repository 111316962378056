<template>
    <div class="has-text-centered is-block">
        <span v-show="loading" class="has-text-centered"><loading icon="spinner-third" spin :pulse="false"></loading></span>
        <span v-show="!loading">
            <img :src="base64string || api + 'api/image/' + name" :alt="alt" :style="{ 'max-width': widthSize, 'width': widthSize, 'margin': 'auto auto' }" @load="Loaded" />
        </span>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {
            name: {
                type: String,
                default: "no-image.jpg"
            },
            size: {
                type: [String, Number],
                default: 13
            },
            alt: {
                type: String,
                default: ""
            },
            data: {
                type: String,
                default: undefined
            }
        },
        emits: ["load"],
        setup(props, context) {
            const api = ref(process.env.VUE_APP_API);

            const loading = ref(true);
            const widthSize = computed(() => {
                if (!isNaN(props.size))
                    return props.size + "em";
                else
                    return props.size;
            });

            const Loaded = () => {
                context.emit("load");
                loading.value = false;
            };

            const base64string = computed(() => {
                var base64string = props.data;
                if (props.data && !props.data.startsWith("data:image/png;base64,")) {
                    base64string = "data:image/png;base64, " + base64string;
                }

                return base64string;
            });

            return {
                api,
                loading,
                widthSize,
                base64string,
                Loaded
            };
        }
    }
</script>
