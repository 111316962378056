<template>
    <div class="inputWrap">
        <label>Height</label>
        <o-input v-model="size.Height" :type="type" min="0"></o-input>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {
            modelValue: {
                type: [Array, Object],
                default: () => { return {}; }
            },
            type: {
                type: String,
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        emits: ["update:modelValue", "selectionChanged"],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $moment = inject("$moment");
            const $success = inject("$success");
            const $error = inject("$error");

            const api = ref(process.env.VUE_APP_API);
            const options = ref([]);
            const name = ref("");
            const isDisabled = ref(false);

            const filteredOptions = computed(() => {
                const results = options.value.filter(option => {
                    return option.Name.toLowerCase().indexOf(name.value.toLowerCase()) >= 0;
                });
                if (results.length === 0) {
                    return options.value;
                }
                return results;
            });

            const Capitalize = (s) => {
                s = s.toLowerCase();
                return s.charAt(0).toUpperCase() + s.slice(1);
            }

            const UpdateOptions = async () => {
                const type = props.type.toLowerCase();
                let src = "";
                if (type === "type") {
                    src = "/api/grilleType";
                } else if (type === "frame") {
                    src = "/api/grilleFrameType";
                } else if (type === "style") {
                    src = "/api/grilleStyleType";
                } else if (type === "material") {
                    src = "/api/grilleMaterialType";
                } else if (type === "finish") {
                    if (props.limitingId > 0) {
                        src = "/api/grilleMaterialType/" + props.limitingId + "/finishes";
                    } else {
                        src = "/api/grilleFinishType";
                    }
                } else if (type === "thickness") {
                    src = "/api/grilleThicknessType";
                }
                try {
                    if (src !== "") {
                        let response = await $http.get(src);
                        options.value = response.data;
                    } else {
                        isDisabled.value = true;
                    }
                }
                catch (err) {
                    console.error(err);
                }
            }

            UpdateOptions();

            const IsValid = (s) => {
                return options.value.find(o => s.toLowerCase() === o.Name.toLowerCase());
            };

            const UpdateModel = () => {
                var model = options.value.filter(option => option.Name === name.value)[0];

                if (model) {
                    context.emit("update:modelValue", model);
                }
            };

            const UpdateSelection = () => {
                name.value = props.modelValue.Name;
            };

            watch(() => name.value, (n, o) => {
                if (n && IsValid(n)) {
                    UpdateModel();
                }
            });

            context.emit("init", {
                Reset: () => {
                    for (var i = 0; i < options.value.length; i++) {
                        options.value[i].selected = false;
                    }
                    UpdateModel();
                }
            });

            onMounted(() => {
                if (props.disabled) {
                    isDisabled.value = true;
                }
                UpdateSelection();
            });

            return {
                api,
                options,
                name,
                filteredOptions,
                isDisabled,
                Capitalize,
                UpdateModel
            };
        }
    }

</script>
<style lang="scss">
</style>
