<template>
    <div v-if="loading">
        <span class="has-text-centered">
            <loading icon="spinner-third" spin :pulse="false"></loading>
        </span>
    </div>
    <span v-else-if="isSummary">
        {{ schedules.length }}
    </span>
    <div v-else>
        <span class="error" v-if="error">Problem loading quotes. Please refresh the page.</span>
        <span v-if="schedules.length == 0 && !error">
            <slot name="empty">
                <div class="my-4">Save schedules for quick access</div>
                <router-link class="button" to="/rfq">Start a new schedule</router-link>
            </slot>
        </span>
        <o-table :data="schedules" v-else>
            <o-table-column label="Created On" field="CreatedOn" sortable>
                <template v-slot="props">
                    {{ $moment.utc(props.row.CreatedOn).local().format("MMM D YYYY h:mma") }}
                </template>
            </o-table-column>
            <o-table-column label="Name" field="Name" sortable>
                <template v-slot="props">
                    {{ props.row.Name }}
                </template>
            </o-table-column>
            <o-table-column label="Quote #" field="QuoteId" sortable>
                <template v-slot="props">
                    {{ props.row.QuoteId }}
                </template>
            </o-table-column>
        </o-table>
    </div>
</template>
<script>
    import {
        getCurrentInstance,
        ref,
        computed,
        watch,
        onMounted,
        inject,
    } from "vue";
    export default {
        props: {
            userId: {
                type: String,
                default: undefined,
            },
            isSummary: {
                type: Boolean,
                default: undefined,
            },
        },
        emits: [],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const loading = ref(true);
            const error = ref(false);
            const schedules = ref([]);

            const GetSchedules = async () => {
                try {
                    const userId = props.userId;
                    if (userId) {
                        const response = await $http.get(
                            "/api/grilleSchedule/user/" + userId + "/list"
                        );
                        schedules.value = response.data;
                        return response;
                    } else {
                        const response = await $http.get("/api/grilleSchedule/list");
                        schedules.value = response.data;
                        return response;
                    }
                } catch (err) {
                    error.value = true;
                }
            };

            onMounted(async () => {
                await GetSchedules();
                loading.value = false;
            });

            return {
                loading,
                error,
                schedules,
            };
        },
    };
</script>
