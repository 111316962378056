<template>
	<div v-if="loading">
		<span class="has-text-centered">
			<loading icon="spinner-third" spin :pulse="false"></loading>
		</span>
	</div>
	<div v-else class="dashboard-table-wrap">
		<span class="error" v-if="error">Problem loading quotes. Please refresh the page.</span>
		<span v-if="quotes.length == 0 && !error">
			<slot name="empty">There are no quotes yet!</slot>
		</span>

		<o-table
			:data="quotes"
			v-else
			:per-page="5"
			class="dashboard-table"
			default-sort="user.first_name"
			aria-next-label="Next page"
			aria-previous-label="Previous page"
			aria-page-label="Page"
			aria-current-label="Current page">
			<o-table-column width="40">
				<template v-slot="props">
					<o-button variant="primary" size="small" @click="OpenQuote(props.row.Id)">
						details
					</o-button>
				</template>
			</o-table-column>
			<o-table-column label="Created On" field="CreatedOn" width="200" sortable>
				<template v-slot="props">
					{{ $moment.utc(props.row.CreatedOn).format("MMM D YYYY h:mma") }}
				</template>
			</o-table-column>
			<o-table-column label="Company" field="Contact.Company" sortable>
				<template v-slot="props">
					{{ props.row.Contact.Company }}
				</template>
			</o-table-column>
			<o-table-column label="Job" field="Contact.Job" sortable>
				<template v-slot="props">
					{{ props.row.Contact.Job }}
				</template>
			</o-table-column>
			<o-table-column label="Document" field="Document.Name" sortable>
				<template v-slot="props">
					<a v-if="props.row.Document?.Id" href="#" @click="Download(props.row.Document)">
						{{ props.row.Document.Name }}
					</a>
					<o-button v-else size="small" variant="info" @click="OpenDocumentModal(props.row.Id)">
						Add file to quote
					</o-button>
				</template>
			</o-table-column>
		</o-table>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	import Schedule from "@/components/modal/schedule.vue";
	import AddDocumentToQuote from "@/components/modal/addDocumentToQuote.vue";

	export default {
		props: {
			userId: {
				type: String,
				default: undefined
			},
			maxRows: {
				type: Number,
				default: undefined
			}
		},
		emits: [],
		setup(props, context) {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $success = inject("$success");
			const $error = inject("$error");

			const loading = ref(true);
			const error = ref(false);
			const quotes = ref([]);

			const filteredQuotes = computed(() => {
				const copy = quotes.value.slice();
				if (props.maxRows) {
					copy.splice(props.maxRows);
				}
				return copy;
			});
			const GetQuotes = async () => {
				try {
					const userId = props.userId;
					if (userId) {
						const response = await $http.get("/api/quote/user/" + userId + "/list");
						quotes.value = response.data;
						return response;
					} else {
						const response = await $http.get("/api/quote/list");
						quotes.value = response.data;
						return response;
					}
				} catch (err) {
					error.value = true;
				}
			};

			const Download = async (row) => {
				try {
					const response = await $http.get("/api/document/" + row.Id, {
						responseType: "blob"
					});

					const blob = response.data;
					var link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.setAttribute("download", row.Name);

					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} catch (err) {
					console.error(err);
				}
			};

			const OpenDocumentModal = (quoteId) => {
				global.$oruga.modal.open({
					parent: this,
					component: AddDocumentToQuote,
					trapFocus: true,
					active: true,
					props: {
						quoteId: quoteId
					},
					events: {
						close: () => {
							GetQuotes();
						}
					}
				});
			};

			const GetGrilles = async (quoteId) => {
				try {
					const response = await $http.get(`/api/quote/${quoteId}`);
					return response.data;
				} catch (err) {
					error.value = true;
				}
			};

			const OpenQuote = async (id) => {
				const g = await GetGrilles(id);

				global.$oruga.modal.open({
					parent: this,
					component: Schedule,
					trapFocus: true,
					active: true,
					props: {
						grilles: g.Grilles
					},
					events: {
						save: (result) => {
							result.close();
						}
					},
					onClose(result) {
						//
					}
				});
			};

			onMounted(async () => {
				await GetQuotes();
				loading.value = false;
			});

			return {
				loading,
				error,
				quotes,
				filteredQuotes,
				OpenQuote,
				OpenDocumentModal,
				Download
			};
		}
	};
</script>
<style lang="scss">
	.dashboard-table-wrap table {
		margin: 0 0.2em;
		max-width: unset;
		text-align: left;
		width: calc(100% - 0.4em) !important;
	}
</style>
