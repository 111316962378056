<template>
	<div class="modal-card">
		<header class="modal-card-head">
			<p class="modal-card-title">Add document to quote</p>
			<o-icon type="button" icon="times" @click="$emit('close')" clickable></o-icon>
		</header>
		<section class="modal-card-body">
			<div class="uploadBox">
				<h2>Upload new document</h2>
				<o-field class="file">
					<o-upload
						v-model="file"
						accept="image/png, image/jpeg, application/pdf, application/msword"
						drag-drop>
						<section class="has-text-centered">
							<p>
								<o-icon icon="upload" size="is-large"></o-icon>
							</p>
							<p>Drop your files here or click to upload</p>
							<loading v-if="uploading" icon="spinner-third" spin :pulse="false"></loading>
						</section>
						<!-- //where are our colors set up? we need to make one for primary. -->
					</o-upload>

					<span class="file-name" v-if="file">{{ file.name }}</span>
				</o-field>
			</div>
			<div v-if="loading">
				<span class="has-text-centered">
					<loading icon="spinner-third" spin :pulse="false"></loading>
				</span>
			</div>
			<div v-else class="dashboard-table-wrap">
				<span class="error" v-if="error">Problem loading documents. Please refresh the page.</span>
				<span v-if="documents.length == 0 && !error">
					<slot name="empty">There are no uploaded documents yet!</slot>
				</span>
				<o-table :data="documents" v-else>
					<o-table-column label="Name" field="Name" sortable width="30em">
						<template v-slot="props">
							<a href="#" @click="Download(props.row)">{{ props.row.Name }}</a>
						</template>
					</o-table-column>
					<o-table-column label="Uploaded On" field="CreatedOn" sortable width="30em">
						<template v-slot="props">
							{{ $moment.utc(props.row.CreatedOn).format("MMM D YYYY h:mma") }}
						</template>
					</o-table-column>

					<o-table-column narrowed label="" field="delete" position="right">
						<template v-slot="props">
							<o-button
								variant="info"
								outlined
								size="small"
								:disabled="props.row.disabled"
								@click="Add(props.row.Id)">
								Add to quote
							</o-button>
						</template>
					</o-table-column>
				</o-table>
			</div>
		</section>
	</div>
</template>

<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";

	export default {
		props: {
			quoteId: {
				type: Number,
				default: null
			}
		},
		setup(props, context) {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $success = inject("$success");
			const $error = inject("$error");

			const loading = ref(true);
			const uploading = ref(false);
			const error = ref(false);
			const documents = ref([]);

			const file = ref();

			const GetDocuments = async () => {
				try {
					const userId = props.userId;
					if (userId) {
						const response = await $http.get("/api/document/user/" + userId + "/list");
						documents.value = response.data;
						return response;
					} else {
						const response = await $http.get("/api/document/list");
						documents.value = response.data;
						return response;
					}
				} catch (err) {
					error.value = true;
				}
			};

			const UploadFile = async () => {
				uploading.value = true;
				console.log("UPLOAD");
				setTimeout(async () => {
					try {
						var formData = new FormData();
						formData.append("file", file.value);
						var response = await $http.post("/api/document/upload", formData, {
							headers: { "Content-Type": "multipart/form-data" }
						});
						file.value = null;

						if (response.data.Id) {
							$success("The file was successfully uploaded.");
						} else {
							$error("Failed to upload file.");
						}

						uploading.value = false;
						Add(response.data.Id);
						return response;
					} catch (err) {
						$error("Failed to upload file.");
					}
				}, 100);
			};

			const Download = async (row) => {
				try {
					const response = await $http.get("/api/document/" + row.Id, {
						responseType: "blob"
					});

					const blob = response.data;
					var link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.setAttribute("download", row.Name);

					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} catch (err) {
					console.error(err);
				}
			};

			const Add = async (Id) => {
				var response = await $http({
					method: "GET",
					url: "/api/quote/" + props.quoteId + "/document/" + Id
				});

				context.emit("close");
			};

			watch(
				() => file.value,
				(n, o) => {
					if (n && n !== o) {
						console.log("WATCH");
						UploadFile();
					}
				}
			);

			onMounted(async () => {
				await GetDocuments();
				loading.value = false;
			});

			return {
				loading,
				uploading,
				error,
				documents,
				file,
				GetDocuments,
				Add,
				Download,
				UploadFile
			};
		}
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/css/globalVars.scss";
	.uploadBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		grid-area: upload;
		padding: 1em;
		background-color: $boxColor;
		height: fit-content;

		& section.ex-center {
			width: 100%;
		}

		& .o-upl {
			border: 1px dashed black;

			&__draggable {
				border: 1em solid transparent !important;
				border-width: 4em 1em !important;
			}
		}

		& .field.has-addons {
			flex-direction: column;
		}
	}
</style>
