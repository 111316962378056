<template>
    <fa-icon :icon="icon" :pulse="pulse" :spin="spin"></fa-icon>
</template>
<script>
    import { ref, computed, watch, onMounted } from "vue";
    export default {
        props: {
            icon: {
                type: String,
                default: "spinner-third"
            },
            pulse: {
                type: Boolean,
                default: true
            },
            spin: {
                type: Boolean,
                default: false
            }
        },
        setup() {
            return {}
        }
    }
</script>