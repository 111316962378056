import { createStore } from 'vuex'
import Axios from 'axios';
import Roles from "@/enums/roles";
//import { raygun } from "@/services/raygun"

export default createStore({
    state: {
        token: localStorage.getItem("AUTH_TOKEN"),
        refreshToken: localStorage.getItem("REFRESH_TOKEN"),
        user: JSON.parse(localStorage.getItem("USER") || "{}"),
        grilles: JSON.parse(sessionStorage.getItem("grilles") || "[]"),
        contact: JSON.parse(sessionStorage.getItem("contact") || "{}"),
        httpGetCache: JSON.parse(sessionStorage.getItem("aagGetCache") || "{}"),
    },
    mutations: {
        SET_USER(state, params) {
            if (params) {
                state.token = params.Token;
                state.refreshToken = params.RefreshToken;
                state.user = params;

                localStorage.setItem("AUTH_TOKEN", params.Token);
                localStorage.setItem("REFRESH_TOKEN", params.RefreshToken);
                localStorage.setItem("USER", JSON.stringify(params));
            }
            else {
                state.token = null;
                state.refreshToken = null;
                state.user = {};

                localStorage.removeItem("AUTH_TOKEN");
                localStorage.removeItem("REFRESH_TOKEN");
                localStorage.removeItem("USER");
            }
        },
        ADD_GRILLES(state, params) {
            state.grilles.push({ ...params });
        },
        SET_GRILLES(state, params) {
            state.grilles = params;
        },
        SET_CONTACT(state, params) {
            state.contact = params;
        },
        SET_GET_RESULT(state, params) {
            state.httpGetCache[params.key] = params.value;
        }
    },
    actions: {
        login: ({ commit, state }, params) => {
            commit("SET_USER", params);
            if (params) {
                Axios.interceptors.request.use(config => { config.headers["Authorization"] = "Bearer " + params.Token; return config; });
            }
        },
        refresh: ({ commit, state }, params) => {
            const user = params;
            localStorage.setItem("AUTH_TOKEN", user.Token);
            localStorage.setItem("REFRESH_TOKEN", user.RefreshToken);
            localStorage.removeItem("USER");
            localStorage.setItem("USER", JSON.stringify(user));
            commit('SET_USER', user);

            /*
            try {
                if (user) {
                    raygun('setUser', {
                        identifier: user.Id,
                        isAnonymous: false,
                        email: user.Email,
                        fullName: user.FirstName + user.LastName
                    });
                }

            } catch (err) {
                console.log("failed raygun refresh");
            }
            */

            return user;
        },
        logout: ({ commit, state }) => {
            commit("SET_USER", null);
        },
        addGrille: ({ commit, state }, params) => {
            commit("ADD_GRILLES", params);
            sessionStorage.setItem("grilles", JSON.stringify(state.grilles));
        },
        setGrilles: ({ commit, state }, params) => {
            commit("SET_GRILLES", params);
            sessionStorage.setItem("grilles", JSON.stringify(state.grilles));
        },
        setContact: ({ commit, state }, params) => {
            commit("SET_CONTACT", params);
            sessionStorage.setItem("contact", JSON.stringify(state.contact));
        },
        setGetResult: ({ commit, state }, params) => {
            commit("SET_GET_RESULT", params);
            sessionStorage.setItem("aagGetCache", JSON.stringify(state.httpGetCache));
        }
    },
    modules: {
    },
    getters: {
        user: state => {
            return state.user;
        },
        isLoggedIn: state => {
            return !!state.token;
        },
        grilles: state => {
            return state.grilles;
        },
        contact: state => {
            return state.contact;
        },
        isAdmin: state => {
            return state.user.Roles && state.user.Roles.some(r => r == Roles.Admin);
        },
        isMember: state => {
            return state.user.Roles && state.user.Roles.some(r => r == Roles.Member);
        },
        HttpGet: state => key => {
            return state.httpGetCache[key];
        }
    }
})
