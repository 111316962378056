<template>
	<div v-if="loading">
		<span class="has-text-centered">
			<loading icon="spinner-third" spin :pulse="false"></loading>
		</span>
	</div>
	<span v-else-if="isSummary">
		{{ documents.length }}
	</span>
	<div v-else class="dashboard-table-wrap">
		<span class="error" v-if="error">Problem loading documents. Please refresh the page.</span>
		<span v-if="documents.length == 0 && !error">
			<slot name="empty">There are no uploaded documents yet!</slot>
		</span>
		<o-table :data="documents" v-else>
			<o-table-column label="Name" field="Name" sortable width="30em">
				<template v-slot="props">
					<a href="#" @click="Download(props.row)">{{ props.row.Name }}</a>
				</template>
			</o-table-column>
			<o-table-column label="Uploaded On" field="CreatedOn" sortable width="30em">
				<template v-slot="props">
					{{ $moment.utc(props.row.CreatedOn).format("MMM D YYYY h:mma") }}
				</template>
			</o-table-column>

			<o-table-column narrowed label="" field="delete" position="right">
				<template v-slot="props">
					<o-button
						variant="danger"
						outlined
						icon-right="trash"
						:disabled="props.row.disabled"
						@click="DeleteDocument(props.row)"></o-button>
				</template>
			</o-table-column>
		</o-table>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	export default {
		props: {
			userId: {
				type: String,
				default: undefined
			},
			isSummary: {
				type: Boolean,
				default: undefined
			}
		},
		emits: [],
		setup(props, context) {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $success = inject("$success");
			const $error = inject("$error");

			const loading = ref(true);
			const error = ref(false);
			const documents = ref([]);

			const GetDocuments = async () => {
				try {
					const userId = props.userId;
					if (userId) {
						const response = await $http.get("/api/document/user/" + userId + "/list");
						documents.value = response.data;
						return response;
					} else {
						const response = await $http.get("/api/document/list");
						documents.value = response.data;
						return response;
					}
				} catch (err) {
					error.value = true;
				}
			};

			const Download = async (row) => {
				try {
					const response = await $http.get("/api/document/" + row.Id, {
						responseType: "blob"
					});

					const blob = response.data;
					var link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.setAttribute("download", row.Name);

					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} catch (err) {
					console.error(err);
				}
			};

			const DeleteDocument = async (row) => {
				if (confirm("Delete this document?")) {
					row.disabled = true;

					$http({ method: "DELETE", url: "/api/document", data: row })
						.then((response) => {
							if (response.status == 200) {
								GetDocuments();
							}
						})
						.catch((error) => {
							$error(error.response);
							row.disabled = false;
						});
				}
			};

			onMounted(async () => {
				await GetDocuments();
				loading.value = false;
			});

			return {
				loading,
				error,
				documents,
				GetDocuments,
				DeleteDocument,
				Download
			};
		}
	};
</script>
<style lang="scss">
	table.table {
		max-width: 56em;
	}
</style>
