import { App } from "vue";

const Success = {
    install(app: App, options = {}) {
        app.provide("$success", (message) => {
            app.config.globalProperties.$oruga.notification.open({
                message: message,
                position: 'bottom',
                duration: 5000,
                variant: 'success',
                rootClass: 'toast-notification'
            });
        });
    }
}

const Error = {
    install(app: App, options = {}) {
        app.provide("$error", (message) => {
            app.config.globalProperties.$oruga.notification.open({
                message: message,
                position: 'bottom',
                duration: 5000,
                variant: 'danger',
                rootClass: 'toast-notification'
            });
        });
    }
}

const Warn = {
    install(app: App, options = {}) {
        app.provide("$warn", (message) => {
            app.config.globalProperties.$oruga.notification.open({
                message: message,
                position: 'bottom',
                duration: 5000,
                variant: 'warning',
                rootClass: 'toast-notification'
            });
        });
    }
}

export default {
    Success,
    Error,
    Warn
}